import wordpress from '../service/wordpress'
import portal from '../service/portal'
// const convert = require('xml-js')
export const strict = false

export const state = () => ({
  language: 'en',
  brands: [],
  news: [],
  barUsps: [],
  productGroups: [],
  universal: [],
  getUniversalMain: {},
  stock: [],
  cartItems: [],
  cartQty: 0,
  priceList: 16,
  retailPriceList: 16,
  cartChanged: false,
  bearer: null,
  favoriteItems: [],
  favoriteItemsFetched: false,
  cartID: null,
  isCartLoaded: false,
  isCartError: false
})

export const getters = {
  getLanguage(state) {
    return state.language
  },
  getBrands(state) {
    return state.brands
  },
  getNews(state) {
    return state.news
  },
  getBarUsps(state) {
    return state.barUsps
  },
  getProductGroups(state) {
    return state.productGroups
  },
  getUniversal(state) {
    return state.universal
  },
  getUniversalMain(state) {
    return state.universalMain
  },
  getPriceList(state) {
    return state.priceList
  },
  getRetailPriceList(state) {
    return state.retailPriceList
  },
  getStock(state) {
    return state.stock
  },
  getCartItems(state) {
    return state.cartItems
  },
  getCartQty(state) {
    return state.cartQty
  },
  getCartChanged(state) {
    return state.cartChanged
  },
  getFavoriteItems(state, productID) {
    return state.favoriteItems;
  },
  getIsFavoritesSet(state) {
    return !!state.favoriteItemsFetched;
  },
  getBearer(state) {
    return state.bearer;
  },
  getCartID(state) {
    return state.cartID;
  },
  getIsCartLoaded(state) {
    return state.isCartLoaded;
  },
  getIsCartError(state) {
    return state.isCartError;
  }
}

export const mutations = {
  SET_LANGUAGE(state, language) {
    state.language = language
  },
  SET_BRANDS(state, brands) {
    state.brands = brands
  },
  SET_NEWS(state, news) {
    state.news = news
  },
  SET_BAR_USPS(state, barUsps) {
    state.barUsps = barUsps
  },
  SET_PRODUCT_GROUPS(state, productGroups) {
    state.productGroups = productGroups
  },
  SET_UNIVERSAL(state, universal) {
    state.universal = universal
  },
  SET_UNIVERSAL_MAIN(state, universalMain) {
    state.universalMain = universalMain
  },
  SET_PRICE_LIST(state, priceList) {
    state.priceList = priceList
  },
  SET_RETAIL_PRICE_LIST(state, priceList) {
    state.retailPriceList = priceList
  },
  SET_STOCK(state, stock) {
    state.stock = stock
  },
  SET_BEARER(state, bearer) {
    state.bearer = bearer
  },
  SET_IS_CART_LOADED(state, isCartLoaded) {
    state.isCartLoaded = isCartLoaded;
  },
  SET_IS_CART_ERROR(state, isCartError) {
    state.isCartError = isCartError;
  },
  SET_CART_ID(state, cartID) {
    state.cartID = cartID;
    window.localStorage.setItem('cartID', cartID);
  },
  SET_CART_ITEMS(state, cartItems) {
    state.cartItems = cartItems;
    window.localStorage.setItem('cartItems', JSON.stringify(state.cartItems));

    state.cartQty = state.cartItems.reduce((sum, item) => sum + item.quantity, 0);
  },
  SET_CART_ITEM(state, cartItem) {
    let initialQuantity = state.cartQty;
    // TODO: trzeba się upewnić że nie będzie konfliktu ze starymi zapisanymi stanami localstorage
    let existingItemIndex = state.cartItems.findIndex(item => item.id === cartItem.id);

    if (existingItemIndex >= 0) {
      state.cartItems[existingItemIndex] = JSON.parse(JSON.stringify(cartItem));
    } else {
      state.cartItems.push(JSON.parse(JSON.stringify(cartItem)));
    }

    window.localStorage.setItem('cartItems', JSON.stringify(state.cartItems));
    let cartQty = state.cartItems.reduce((sum, item) => sum + item.quantity, 0);

    state.cartQty = cartQty;
    state.cartChanged = (initialQuantity !== cartQty);
  },
  REMOVE_CART_ITEM(state, cartItemID) {
    let initialQuantity = state.cartQty;
    state.cartItems = state.cartItems.filter(item => item.id !== cartItemID);

    window.localStorage.setItem('cartItems', JSON.stringify(state.cartItems));
    let cartQty = state.cartItems.reduce((sum, item) => sum + item.quantity, 0);

    state.cartQty = cartQty;
    state.cartChanged = (initialQuantity !== cartQty);
  },
  SET_FAVORITE_ITEMS(state, productsIDs) {
    state.favoriteItems = productsIDs;
  },
  ADD_FAVORITE_ITEM(state, itemProductID) {
    state.favoriteItems.push(itemProductID);
  },
  REMOVE_FAVORITE_ITEM(state, itemProductID) {
    let itemOnListIndex = state.favoriteItems.indexOf(itemProductID);

    if (itemOnListIndex > -1) {
      state.favoriteItems.splice(itemOnListIndex, 1);
    }
  },
  REMOVE_ALL_FAVORITE_ITEMS(state) {
    state.favoriteItems = [];
  },
  SET_FAVORITE_ITEMS_FETCHED(state, isFetched) {
    state.favoriteItemsFetched = isFetched;
  }
}

export const actions = {
  loadSavedCart ({ commit, dispatch }) {
    commit('SET_IS_CART_LOADED', false);
    commit('SET_IS_CART_ERROR', false);
    let cartIdLocal = window.localStorage.getItem('cartID');
    if (cartIdLocal) {
      commit('SET_CART_ID', cartIdLocal);

      dispatch('fetchAndSetCartItems');
      return;
    }

    commit('SET_CART_ITEMS', []);
    commit('SET_IS_CART_LOADED', true);
  },

  async fetchAndSetCartItems ({ commit, getters }) {
    commit('SET_IS_CART_LOADED', false);
    commit('SET_IS_CART_ERROR', false);

    try {
      let response = await portal.get(
        `cart/${getters.getCartID}?lang=${getters.getLanguage}`,
        {
          headers: {
            'Authorization': `${getters.getBearer}`
          }
        }
      )

      if (!response.status === 200) {
        commit('SET_IS_CART_ERROR', true);
        commit('SET_IS_CART_LOADED', true);
        throw new Error('cart fetch request failed');
      }
      let cartItems = response.data.data;
      commit('SET_CART_ITEMS', cartItems);
      commit('SET_IS_CART_LOADED', true);
      return cartItems;
    } catch (error) {
      console.error('Error fetching cart items from API:', error);
      commit('SET_IS_CART_ERROR', true);
      commit('SET_IS_CART_LOADED', true);
    }
  },
  async addItemToCart ({ commit, getters }, product) {
    if (!product) {
      console.warn('no product specified');
      return;
    }

    let cartID = getters.getCartID;

    commit('SET_IS_CART_LOADED', false);
    commit('SET_IS_CART_ERROR', false);

    try {
      let response = await portal.post(
        `cart/add-item?lang=${getters.getLanguage}`,
        {
          ...product,
          cart_id: cartID
        },
        {
          headers: {
            'Authorization': `${getters.getBearer}`
          }
        }
      )

      if (!response.status === 200 || !response.data.success) {
        commit('SET_IS_CART_ERROR', true);
        commit('SET_IS_CART_LOADED', true);
        throw new Error('Error fetching cart items from API');
      }

      if (!cartID) {
        commit('SET_CART_ID', response.data.cart_id);
      }

      commit('SET_CART_ITEM', response.data.item);
      commit('SET_IS_CART_LOADED', true);
    } catch (error) {
      commit('SET_IS_CART_ERROR', true);
      commit('SET_IS_CART_LOADED', true);
      console.error('Error fetching cart items from API:', error);
    }
  },

  async updateCartItemQuantity ({ commit, getters }, { cartItemID, newQuantity }) {
    let currentItem = getters.getCartItems.find(item => item.id === cartItemID);

    if (!currentItem) {
      console.warn('cart item for update not found');
      return;
    }

    if (Number(currentItem.quantity) === Number(newQuantity)) {
      return;
    }
    commit('SET_IS_CART_LOADED', false);
    commit('SET_IS_CART_ERROR', false);
    try {
      let response = await portal.post(
        `cart/${getters.getCartID}/change-quantity/${cartItemID}?lang=${getters.getLanguage}`,
        {
          quantity: newQuantity
        },
        {
          headers: {
            'Authorization': `${getters.getBearer}`
          }
        }
      )

      if (!response.status === 200 || !response.data.success) {
        commit('SET_IS_CART_ERROR', true);
        commit('SET_IS_CART_LOADED', true);
        throw new Error('Error updating cart item quantity');
      }

      commit('SET_CART_ITEM', response.data.item);
      commit('SET_IS_CART_LOADED', true);
    } catch (error) {
      commit('SET_IS_CART_ERROR', true);
      commit('SET_IS_CART_LOADED', true);
      console.error('Error updating cart item quantity:', error);
    }
  },

  async removeItemFromCart ({ commit, getters }, cartItemID) {
    commit('SET_IS_CART_LOADED', false);
    commit('SET_IS_CART_ERROR', false);
    try {
      let response = await portal.delete(
        `cart/${getters.getCartID}/remove-item/${cartItemID}`,
        {
          headers: {
            'Authorization': `${getters.getBearer}`
          }
        }
      )

      if (!response.status === 200 || !response.data.success) {
        commit('SET_IS_CART_ERROR', true);
        commit('SET_IS_CART_LOADED', true);
        throw new Error('Error removing item from cart');
      }

      commit('REMOVE_CART_ITEM', cartItemID);
      commit('SET_IS_CART_LOADED', true);
    } catch (error) {
      commit('SET_IS_CART_ERROR', true);
      commit('SET_IS_CART_LOADED', true);
      console.error('Error removing item from cart:', error)
    }
  },
  async clearCart ({ commit, getters }) {
    commit('SET_IS_CART_LOADED', false);
    commit('SET_IS_CART_ERROR', false);
    try {
      let response = await portal.delete(
        `cart/${getters.getCartID}/remove-all`,
        {
          headers: {
            'Authorization': `${getters.getBearer}`
          }
        }
      )

      if (!response.status === 200 || !response.data.success) {
        commit('SET_IS_CART_ERROR', true);
        commit('SET_IS_CART_LOADED', true);
        throw new Error('Error removing item from cart');
      }

      commit('SET_CART_ITEMS', []);
      commit('SET_IS_CART_LOADED', true);
    } catch (error) {
      commit('SET_IS_CART_ERROR', true);
      commit('SET_IS_CART_LOADED', true);
      console.error('Error removing item from cart:', error);
    }
  },
  async nuxtServerInit({ commit }, { redirect, app, req }) {
    let language = req._parsedUrl.path.split('/')
    language.shift()
    language = language[0]

    if (language.length === 2) {
      app.$cookies.set('language', language, {
        path: '/',
        maxAge: 60 * 60 * 24 * 365
      })
    } else if (language.length < 2) {
      language = app.$cookies.get('language')
        ? app.$cookies.get('language')
        : 'en'
    } else {
      language = app.$cookies.get('language')
        ? app.$cookies.get('language')
        : 'en'
      if (language === 'en') {
        redirect(`${req._parsedUrl.path}`)
      } else {
        redirect(`/${language}${req._parsedUrl.path}`)
      }
    }

    if (req._parsedUrl.path.length < 2) {
      if (language === 'en') {
        redirect(`/`)
      } else {
        redirect(`/${language}`)
      }
    }
    commit('SET_LANGUAGE', language);

    let currentPriceList = getUserPriceList(language, app.$auth);
    let currentRetailPriceList = currentPriceList;

    if (app.$auth.loggedIn) {
      currentRetailPriceList = getRetailPriceList(language, currentPriceList);
    }

    commit('SET_PRICE_LIST', currentPriceList);
    commit('SET_RETAIL_PRICE_LIST', currentRetailPriceList);

    const [
      ourBrands,
      news,
      barUsps,
      productGroups
    ] = await Promise.all([
      wordpress.get('brands'),
      wordpress.get(app.i18n.t('endpoints.cat_news')),
      wordpress.get(app.i18n.t('endpoints.bar_usps')),
      portal.get(`productgroups?lang=${language}`)
    ])
    commit('SET_BRANDS', ourBrands.data)
    commit('SET_NEWS', news.data)
    commit('SET_BAR_USPS', barUsps.data)

    const newProductGroups = []
    let universal = {}

    // ID 5 for "parts by category" - Used to be Universal
    const univeralId = 5
    for (let i = 0; i < productGroups.data.length; i++) {
      const productGroup = productGroups.data[i]
      for (let j = 0; j < productGroups.data[i].translations.length; j++) {
        if (
          productGroups.data[i].translations[j].language.language_code === 'en'
        ) {
          productGroup.fallback_name =
            productGroups.data[i].translations[j].name
          if (productGroups.data[i].id === univeralId) {
            universal = productGroups.data[i]
          }
        }
        if (
          productGroups.data[i].translations[j].language.language_code ===
          language
        ) {
          productGroup.name = productGroups.data[i].translations[j].name
        }
      }
      newProductGroups.push(productGroup)
    }
    commit('SET_PRODUCT_GROUPS', newProductGroups)

    const [brands] = await Promise.all([
      portal.get(`productgroups/${univeralId}?lang=${language}`)
    ])
    const newBrands = []
    for (let i = 0; i < brands.data[0].brands.length; i++) {
      const brand = brands.data[0].brands[i]
      if (brands.data[0].brands[i] !== null) {
        for (let j = 0; j < brands.data[0].brands[i].translations.length; j++) {
          if (
            brands.data[0].brands[i].translations[j].language.language_code ===
            'en'
          ) {
            brand.fallback_name = brands.data[0].brands[i].translations[j].name
          }
          if (
            brands.data[0].brands[i].translations[j].language.language_code ===
            language
          ) {
            brand.name = brands.data[0].brands[i].translations[j].name
          }
        }
        newBrands.push(brand)
      }
    }
    for (let j = 0; j < universal.translations.length; j++) {
      if (universal.translations[j].language.language_code === 'en') {
        universal.fallback_name = universal.translations[j].name
      }
      if (universal.translations[j].language.language_code === language) {
        universal.name = universal.translations[j].name
      }
    }
    commit('SET_UNIVERSAL_MAIN', universal)
    commit('SET_UNIVERSAL', newBrands)

    // GET XML STOCK (TEMP)
    /* const stockUrl = 'https://www.apairltd.com/xml/pricefile20200526103755.xml'
    const [stockXml] = await Promise.all([this.$axios.get(stockUrl)])
    const stockJson = convert.xml2json(stockXml.data, {
      compact: false,
      spaces: 4
    })
    commit('SET_STOCK', stockJson) */

    if (app.$auth.$state.loggedIn) {
      commit('SET_BEARER', app.$cookies.get('apa__token_local'))
    }
  },

  async setLanguage({ commit, state }, language) {
    commit('SET_LANGUAGE', language)

    let currentPriceList = getUserPriceList(language, this.app.$auth);
    let currentRetailPriceList = currentPriceList;

    if (this.app.$auth.loggedIn) {
      currentRetailPriceList = getRetailPriceList(language, currentPriceList);
    }

    commit('SET_PRICE_LIST', currentPriceList)
    commit('SET_RETAIL_PRICE_LIST', currentRetailPriceList);

    const [news, barUsps, productGroups] = await Promise.all([
      wordpress.get(this.app.i18n.t('endpoints.cat_news')),
      wordpress.get(this.app.i18n.t('endpoints.bar_usps')),
      portal.get(`productgroups?lang=${language}`)
    ])
    commit('SET_NEWS', news.data)
    commit('SET_BAR_USPS', barUsps.data)

    const newProductGroups = []
    let universal = {}
    for (let i = 0; i < productGroups.data.length; i++) {
      const productGroup = productGroups.data[i]
      for (let j = 0; j < productGroups.data[i].translations.length; j++) {
        if (
          productGroups.data[i].translations[j].language.language_code === 'en'
        ) {
          productGroup.fallback_name =
            productGroups.data[i].translations[j].name
          if (productGroups.data[i].translations[j].id === 5) {
            universal = productGroups.data[i]
          }
        }
        if (
          productGroups.data[i].translations[j].language.language_code ===
          language
        ) {
          productGroup.name = productGroups.data[i].translations[j].name
        }
      }
      newProductGroups.push(productGroup)
    }
    commit('SET_PRODUCT_GROUPS', newProductGroups)

    // ID 5 for "parts by category" - Used to be Universal
    const [brands] = await Promise.all([
      portal.get(`productgroups/5?lang=${language}`)
    ])
    const newBrands = []
    for (let i = 0; i < brands.data[0].brands.length; i++) {
      const brand = brands.data[0].brands[i]
      if (brands.data[0].brands[i] !== null) {
        for (let j = 0; j < brands.data[0].brands[i].translations.length; j++) {
          if (
            brands.data[0].brands[i].translations[j].language.language_code ===
            'en'
          ) {
            brand.fallback_name = brands.data[0].brands[i].translations[j].name
          }
          if (
            brands.data[0].brands[i].translations[j].language.language_code ===
            language
          ) {
            brand.name = brands.data[0].brands[i].translations[j].name
          }
        }
        newBrands.push(brand)
      }
    }
    for (let j = 0; j < universal.translations.length; j++) {
      if (universal.translations[j].language.language_code === 'en') {
        universal.fallback_name = universal.translations[j].name
      }
      if (universal.translations[j].language.language_code === language) {
        universal.name = universal.translations[j].name
      }
    }
    commit('SET_UNIVERSAL_MAIN', universal)
    commit('SET_UNIVERSAL', newBrands)
  }
}

function getUserPriceList (language, authObj) {
  let priceList = 16;

  if (language === 'ie') {
    priceList = 1;
  } else if (language !== 'en') {
    priceList = 26;
  }

  if (authObj.loggedIn) {
    priceList = authObj.user.customer_type_id;
  }

  return priceList;
}

function getRetailPriceList (language, userPriceList) {
  let basePriceList = 16;
  if (language === 'ie') {
    basePriceList = 1;
  } else if (language !== 'en') {
    basePriceList = 26;
  }

  if (!userPriceList) {
    return basePriceList;
  }

  if (userPriceList >= 26) {
    return 26;
  }

  return basePriceList;
}
